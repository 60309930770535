import { RegisterData } from '../../domain/RegisterData';

export const RegisterDataMap = {
  toPersistence: (registerData: RegisterData) => ({
    email: registerData.email,
    password: registerData.password,
    confirmPassword: registerData.password,
  }),
  toLogin: (registerData: RegisterData) => ({
    email: registerData.email,
    password: registerData.password,
  }),
};
