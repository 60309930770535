import { UnifiedRegisterData } from '../../domain/entity/UnifiedRegisterData';

export const UnifiedRegister = {
  toPersistence: (unifiedRegister: UnifiedRegisterData) => ({
    email: unifiedRegister.email,
    phone: unifiedRegister.phone,
    userName: unifiedRegister.name,
    identifier: unifiedRegister.identifier,
    password: unifiedRegister.password,
    confirmPassword: unifiedRegister.password,
    businessName: unifiedRegister.businessName,
  }),
};
