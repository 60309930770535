import { DetailError } from './AuthStore';

export class AuthError extends Error {
  details: DetailError[];

  constructor(...args) {
    super(...args);
    this.details = [];
  }

  setDetails(details: DetailError[]) {
    this.details = details;
  }
}
