import { useState } from 'react';

export const useSubmitError = () => {
  const [submitError, setSubmitError] = useState(false);
  const onSubmitError = (errors: { [_: string]: any }) => {
    const errorField = Object.keys(errors)[0];
    setSubmitError(errors[errorField]);
  };

  return {
    submitError,
    onSubmitError,
  };
};
