import axios from '../../../../src/helpers/axios';
import { AuthError } from '../../domain/AuthError';

import { LoginCredential } from '../../domain/LoginCredential';
import { LoginCredentialMap } from '../mappers/loginCredential.map';
import { RegisterDataMap } from '../mappers/registerData.map';
import { RegisterData } from '../../domain/RegisterData';
import { ForgotPassword } from '../../domain/ForgotPassword';
import { ForgotPasswordMap } from '../mappers/forgotPassword.map';
import { AuthRepository } from '../../domain/AuthRepository';
import { CompleteRegisterData } from '../../domain/CompleteRegisterData';
import { UserInfo } from '../../domain/UserInfo';
import { BusinessInfo } from '../../domain/BusinessInfo';
import { BusinessInfolMap } from '../mappers/businessInfo.map';
import { AccessTokenMap } from '../mappers/accessToken.map';
import { UnifiedRegisterData } from '../../domain/entity/UnifiedRegisterData';
import { UnifiedRegister } from '../mappers/unifiedRegister.map';

export const useAuthRepository = (): AuthRepository => {
  const unhandledError = 'ERROR_UNHANDLED_SERVER_ERROR';

  const login = async (loginCredential: LoginCredential) => {
    try {
      const response = await axios.post(
        '/api/user/login',
        LoginCredentialMap.toPersistence(loginCredential)
      );
      if (!response.data) return response.data;
      return AccessTokenMap.toDomain(response.data);
    } catch (error) {
      const errorCode = error?.response?.data?.errorCode || unhandledError;
      throw new AuthError(errorCode);
    }
  };

  const register = async (registerData: RegisterData) => {
    try {
      await axios.post(
        '/api/user',
        RegisterDataMap.toPersistence(registerData)
      );
    } catch (error) {
      const errorCode = error?.response?.data?.errorCode || unhandledError;
      throw new AuthError(errorCode);
    }
  };

  const completeRegister = async (
    userId: UserInfo['id'],
    completeRegisterData: CompleteRegisterData
  ) => {
    try {
      await axios.put(`/api/user/${userId}`, completeRegisterData);
      const response = await axios.post('/api/user/refresh');
      return AccessTokenMap.toDomain(response.data);
    } catch (error) {
      if (error.response.status === 401) {
        throw new AuthError('ERROR_UNAUTHORIZED_COMPLETE_USER_INFO');
      }
      throw new AuthError(error.response.data.errorCode || unhandledError);
    }
  };

  const addBusinessInfo = async (businessInfo: BusinessInfo) => {
    try {
      const { data: businessData } = await axios.post(
        '/api/business',
        BusinessInfolMap.toPersistence(businessInfo)
      );
      if (businessData.id) {
        await axios.get(`api/business/${businessData.id}/entityfeature`, {});
        axios.post(`/api/business/${businessData.id}/document`);
      }
      const response = await axios.post('/api/user/refresh');
      return AccessTokenMap.toDomain(response.data);
    } catch (error) {
      if (error.response.status === 401) {
        throw new AuthError(error.response.data.message);
      }
      throw new AuthError(error.response.data.errorCode || unhandledError);
    }
  };

  const forgotPassword = async (forgotPasswordCredential: ForgotPassword) => {
    try {
      await axios.post(
        '/api/user/resetpassword',
        ForgotPasswordMap.toPersistence(forgotPasswordCredential)
      );
    } catch (error) {
      const errorCode = error?.response?.data?.errorCode || unhandledError;
      throw new AuthError(errorCode);
    }
  };

  const registerUnififed = async (unifiedRegister: UnifiedRegisterData) => {
    try {
      const { data } = await axios.post(
        '/api/user/register',
        UnifiedRegister.toPersistence(unifiedRegister)
      );
      if (!data) {
        throw new Error(unhandledError);
      }
      return data;
    } catch (error) {
      const errorCode = error?.response?.data?.errorCode || unhandledError;
      const exception = new AuthError(errorCode);
      exception.setDetails(error?.response?.data?.details || []);
      throw exception;
    }
  };

  return {
    login,
    completeRegister,
    addBusinessInfo,
    forgotPassword,
    register,
    registerUnififed,
  };
};
