import { Box, MuiThemeProvider } from '@material-ui/core';

import { TopbarPublic } from 'components/layout/navigation';
import { theme } from '@features/auth/ui/common/theme/theme';

interface AuthLayoutProps {
  children: React.ReactNode;
}

function AuthLayout({ children }: AuthLayoutProps) {
  return (
    <>
      <TopbarPublic />

      <MuiThemeProvider theme={theme}>
        <Box minHeight="100vh" pt={8} bgcolor="white">
          {children}
        </Box>
      </MuiThemeProvider>
    </>
  );
}

export default AuthLayout;
